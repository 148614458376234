@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;




[data-sparta-container] .#{$moduleNameSpace} {
  margin-top: $spacing-20;
  margin-bottom: $spacing-20;
  @media #{$medium-up} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  h2.headline {
    font-size: 24px;
    text-align: center;

    @media #{$medium-up} {
      font-size: 28px;
    }
    @media #{$large-up} {
      font-size: 34px;
    }
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    padding: 0;

        @media #{$medium-up} {
      flex-wrap: nowrap;
    }
  }

  .flex-item {
    border: 1px solid $color-gray-s20;
    flex: 1 0 40%;
    font-size: 1.5em;
    height: auto;
    line-height: 16px;
    margin: 5px;
    padding: $spacing-10;
    text-align: center;

    &:hover {
      background-color: $color-gray-s05;
      cursor: pointer;
      text-decoration: none;
    }

    @media #{$medium-up} {
      flex: 1 0 20%;
      margin: $spacing-20;
    }

    @media #{$large-up} {
      line-height: 24px;
    }

    .sign-in-text {
      color: $color-gray;
      font-size: 14px;
      padding-bottom: $spacing-10;
    }

    .icon {
      padding-bottom: 10%;
      padding-top: 10%;
      @media #{$medium-up} {
        padding-bottom: 5%;
        padding-top: 5%;
      }
      @media #{$large-up} {
        padding-bottom: 10%;
        padding-top: 10%;
      }
      img {
        height: 50px;
        width: auto;
        @media #{$large-up} {
          height: 70px;
        }

      }
    }

    .headline {
      color: $color-royal-one;
      font-size: 16px;
      @media #{$large-up} {
        font-size: 20px;
      }
    }
  }
  .flex-item:before {
    content: '';
    float: left;
    padding-top: 100%;
  }
}
